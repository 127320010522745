import { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { ButtonPrimary } from './layout/StyledComponents';
import { ContactInfo } from './Contact-Info';
import { css } from '@emotion/react';

const ContactPanel = styled.div`
    .office-photo {
        margin-top: 72px;
    }
    .contact-column {
        position: relative;
    }
    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        align-items: center;
        .office-photo {
            width: 100%;
            margin-top: 72px;
            border-radius: 15px;
            img {
                border-radius: 15px;
                width: 100%;
            }
        }
    }
    @media ${device.laptop} {
        margin-top: 128px;
        flex-direction: row;
        justify-content: space-between;
        .contact-column {
            width: 480px;
        }
        .office-photo {
            position: relative;
            right: 16px;
            width: 50%;
            height: 493px;
        }
    }
    @media ${device.desktop} {
        h2 {
            width: 600px;
        }
    }
`;

const Form = styled.form`
    margin: 0 auto;
    .flex {
        display: flex;
        justify-content: space-between;
        div {
            width: 47%;
        }
    }
    button {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: ${colors.yellow3};
    }
    textarea {
        height: 92px;
        width: 100%;
    }
    input {
        width: 100%;
    }
    div {
        margin: 8px 0px;
    }
    label {
        margin-bottom: 6px;
        display: block;
    }
    @media ${device.tablet} {
        width: 630px;
    }
    @media ${device.laptop} {
        width: 100%;
    }
`;

const SchedulePanel = styled.div`
    margin-top: 72px;
    iframe {
        height: 348.71px;
        border-radius: 15px;
    }

    > div:last-of-type {
        position: relative;
    }

    @media ${device.tablet} {
        .flex {
            justify-content: space-between;

            display: flex;
        }
        section {
            margin-top: 10px;
        }
    }
    @media ${device.laptop} {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        h2 {
            max-width: 400px;
        }
        iframe {
            width: 502px;
            margin-top: 32px;
            height: 633.79px;
        }
        .flex {
            max-width: 404px;
            display: block;
        }
    }
    @media ${device.laptopL} {
        h2 {
        }
        iframe {
            margin-top: 72px;
            width: 552px;
            height: 633.79px;
        }
    }
`;

const Hours = styled.div`
    margin: 0;
    display: flex;
    justify-content: space-between;
    ul {
        list-style: none;
        span {
            display: block;
            color: ${colors.gray4};
        }
        li {
            margin: 8px 0px;
        }
    }
    ul:first-of-type {
        font-weight: 700;
        text-align: left;
    }
    ul:last-of-type {
        text-align: right;
    }
    @media ${device.tablet} {
        width: 45%;
    }
    @media ${device.laptop} {
        width: 100%;
    }
`;

export const ContactUs = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <div className="container">
            <ContactPanel className="container">
                <StaticImage
                    className="office-photo"
                    src="../images/office.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="Clermont Kids dental office"
                />
                <div className="contact-column">
                    <StaticImage
                        src="../images/doodle.png"
                        alt="doodle"
                        width={720}
                        css={css`
                            position: absolute;
                            top: 0;
                            left: -32px;
                            right: -32px;
                            bottom: 0;
                            z-index: -1;

                            @media (min-width: 1280px) {
                                right: -64px;
                                left: -64px;
                            }
                        `}
                    />
                    <h2>Contact Us</h2>
                    <Form onSubmit={onSubmit}>
                        <div id="contact">
                            <label htmlFor="name">
                                Name *
                                <input required id="name" name="name" type="text" />
                            </label>
                        </div>
                        <div>
                            <label htmlFor="email">
                                Email address *
                                <input required id="email" name="email" type="text" />
                            </label>
                        </div>
                        <div>
                            <label htmlFor="phone">
                                Phone number *
                                <input required id="phone" name="phone" type="tel" />
                            </label>
                        </div>
                        <div className="flex">
                            <div>
                                <label htmlFor="preferred-date">Preferred Date *</label>
                                <input
                                    required
                                    id="preferred-date"
                                    name="preferred-date"
                                    type="date"
                                />
                            </div>
                            <div>
                                <label htmlFor="preferred-time">Preferred Time *</label>
                                <input
                                    required
                                    id="preferred-time"
                                    name="preferred-time"
                                    type="time"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="message">Message</label>
                            <textarea id="message" name="message" />
                        </div>
                        <ButtonPrimary
                            type="submit"
                            disabled={submitState === 'submitting'}
                            color="yellow"
                            fill
                        >
                            {submitState === 'submitting' ? 'Sending message...' : 'Send Request'}
                        </ButtonPrimary>
                        <p
                            style={{
                                fontWeight: 'bold',
                                color: `${colors.blue3}`,
                                transition: 'opacity .2s ease-in',
                                opacity: `${submitState === 'submitted' ? '1' : '0'}`,
                            }}
                        >
                            Thank You! Your message has been sent
                        </p>
                    </Form>
                </div>
            </ContactPanel>
            <SchedulePanel>
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.2615563586564!2d-81.732598!3d28.531856999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e78fa972003fe3%3A0x659ad2489513a941!2sClermont%20Kids%20Dentistry!5e0!3m2!1sen!2s!4v1733162432155!5m2!1sen!2s"
                        width="100%"
                        loading="lazy"
                        title="Clermont Kids Dentistry & Orthodontics"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                    />
                </div>
                <div>
                    <StaticImage
                        src="../images/doodle.png"
                        alt="doodle"
                        width={680}
                        css={css`
                            position: absolute;
                            top: 0;
                            left: -32px;
                            right: -32px;
                            bottom: 0;
                            z-index: -1;

                            @media (min-width: 1280px) {
                                right: -48px;
                                left: -48px;
                            }
                        `}
                    />
                    <h2>Schedule Your visit</h2>
                    <div className="flex">
                        <ContactInfo color="blue" />
                        <Hours className="hours-container">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li style={{ height: '20px' }}></li>
                                <li>Sunday</li>
                            </ul>
                            <ul>
                                <li>8:30 AM - 5 PM</li>
                                <li>8:30 AM - 5 PM</li>
                                <li>8:30 AM - 5 PM</li>
                                <li>8:30 AM - 5 PM</li>
                                <li>8:30 AM - 5 PM</li>
                                <li>
                                    8 AM - 1 PM<span>(By Appointment only)</span>
                                </li>
                                <li>Closed</li>
                            </ul>
                        </Hours>
                    </div>
                </div>
            </SchedulePanel>
        </div>
    );
};
